import React, { useEffect, useState, useContext } from 'react';
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    CircularProgress,
    Alert,
    Divider

} from '@mui/material';
import './style.css'; // Import the CSS file
import { endPointAPiBoleraApp } from '../api/api-request'
import HeaderLogoBoliche from '../header';
import { useNavigate } from 'react-router-dom';
import DisplayReserva from './DisplayReserva';
import { DataContext } from '../../store/DataContext';

const ModifyReserva = () => {
    const [formData, setFormData] = useState({
        primerApellido: '',
        email: '',
        codigoConfirmacion: ''

    });

    const [openPopup, setOpenPopup] = useState(false);
    const [warningMsg, setWarningMsg] = useState(false);
    const [openWarningPopup, setWarningPopup] = useState(false);
    const [getMsgConsult, setMsgConsult] = useState('')
    const [loader, setLoader] = useState(false)
    const [getStateConsult, setStateConsult] = useState(false)
    //== DATA Reserved
    const [getDataRsvd, setDataRsvd] = useState([])
    const [getDataContacto, setDataContacto] = useState([])
    //== Show Reserve Card
    const [getInfoDataRsv, setInfoDataRsv] = useState(false)
    const [selectedHour, setSelectedHour] = useState('');
    const [getRsvData, setRsvData] = useState([])
    //=Navigate to Validate PAY
    const navigate = useNavigate();
    //DataProvider
    const { formDataUser, updateFormData, dateHour, updateFormDateHour } = useContext(DataContext);
    //data Form
    const [getNombre, setNombre] = useState('');
    const [getFecha, setFecha] = useState('');
    const [getCantidad, setCantidad] = useState('');
    const [getHora, setHora] = useState('');
    const [getIdReservaAlquiler, setIdReservaAlquiler] = useState('');
    const [getIdSede, setIdSede] = useState('');
    const [getCantidadPersonas, setCantidadPersonas] = useState('');
    const [getIdProductoAlquiler, setIdProductoAlquiler] = useState('');
    const [getIdReserva, setIdReserva] = useState('');

    //disable button siguiente
    const [getDisableBtn, setDisableBtn] = useState(true)
    const [getNewFecha, setNewFecha] = useState('');
    const [getNewHour, setNewHour] = useState('');
    const [getNewIdHour, setNewIdHour] = useState('');




    const handleSubmit = (e) => {
        e.preventDefault();
        handleOpenPopup()
        if (!formEmptyStatus) {
            //Open Warning Window
            openWarningPopupWin()
            updateReserva()
            console.log('CALL UPDATE :::::::: ')
        }

    };

    const handleOpenPopup = () => {
        setOpenPopup(true)
        setWarningPopup(false)
        setWarningMsg(true)
        //Loader setup
        setLoader(true)

    }

    const handleClosePopup = () => {
        setOpenPopup(false)
    }

    const openWarningPopupWin = () => {
        setWarningPopup(true)
    }

    const WinTiloPayPOPUP = ({ message, stateValidate, getDataRsvd }) => {
        return (
            <Dialog open={openPopup} maxWidth="md" fullWidth>
                {
                    !loader
                        ?
                        <>
                            <DialogContent>
                                {

                                    stateValidate
                                        ?
                                        <div>
                                            <Alert severity="success">{message}</Alert>
                                            <DisplayReserva
                                                name={getDataRsvd.reservaContacto.nombreCompleto}
                                                email={getDataRsvd.reservaContacto.email}
                                                phone={getDataRsvd.reservaContacto.telefono}
                                                nameSede={getDataRsvd.reservaProductoAlquiler.sedeNombre}
                                                date={getDataRsvd.reservaProductoAlquiler.fecha}
                                                hour={getDataRsvd.reservaProductoAlquiler.horaDiaDescripcion}
                                                persons={getDataRsvd.reservaProductoAlquiler.cantidadPersonas}
                                                codigoReserva={getDataRsvd.reservaConfirmacion.codigoConfirmacion}
                                                description={getDataRsvd.reservaProductoAlquiler.descripcion}
                                                cantidadMaximaPersonas={getDataRsvd.reservaProductoAlquiler.cantidadPersonas}
                                                subTotal={getDataRsvd.subTotal}
                                                totalComisiones={getDataRsvd.totalComisiones}
                                                precio={getDataRsvd.total}
                                            />
                                        </div>

                                        :
                                        <Alert severity="error">{message}</Alert>
                                }
                                <button onClick={handleClosePopup} className="btnSend">
                                    Modificar
                                </button>
                            </DialogContent>
                            <DialogActions>
                                <button onClick={handleClosePopup} className="btnSend">
                                    Regresar
                                </button>
                            </DialogActions>
                        </>
                        : <div style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <CircularProgress style={{ color: 'purple' }} />
                            <Typography >
                                Cargando...
                            </Typography>

                        </div>
                }
            </Dialog>
        )
    }


    //evaluate if form is empty fields newFecha newHour
    const isFormEmpty = (getNewFecha, getNewHour) => {
        if (getNewFecha === '' || getNewHour === '') {
            return true
        } else {
            return false
        }
    };


    let formEmptyStatus = isFormEmpty(getNewFecha, getNewHour);
    console.log('isFormEmpty', formEmptyStatus)


    //Get Data Info Reserve

    const callReserveSaved = async (getIdReserve) => {

        const endPointGetReserve = `${endPointAPiBoleraApp}/api/External/GetDetailsById/` + getIdReserve

        try {
            const response = await fetch(endPointGetReserve, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'AuthorizationToken': 'e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e',
                    'Accept-Language': 'es-MX'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const getData = await response.json();
            // Use the getData
            console.warn('Filled form with reserve saved', getData);
            setDataRsvd(getData.data)
            setDataContacto(getData.data.reservaContacto)
            updateFormDateHour(getData.data)
            setInfoDataRsv(true)
            //saveName
            setNombre(getData.data.reservaContacto.nombreCompleto)
            let cleanFecha = getData.data.reservaProductoAlquiler.fecha.split('T')[0];
            setFecha(cleanFecha)
            setHora(getData.data.reservaProductoAlquiler.horaDiaDescripcion)
            setCantidad(getData.data.reservaProductoAlquiler.cantidadPersonas)
            setIdReservaAlquiler(getData.data.reservaProductoAlquiler.idReservaProductoAlquiler)
            setIdSede(getData.data.reservaProductoAlquiler.idSede)
            setIdProductoAlquiler(getData.data.reservaProductoAlquiler.idProductoAlquiler)
            setCantidadPersonas(getData.data.reservaProductoAlquiler.cantidadPersonas)
            setIdReserva(getData.data.idReserva)


        } catch (error) {
            // Handle the error
            console.error('There was an error!', error);
        }

    }


    const setModifyReserve = async (data = {}) => {

        const endPointGetReserve = `${endPointAPiBoleraApp}/api/External/ActualizarReserva`

        try {
            const response = await fetch(endPointGetReserve, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "AuthorizationToken": "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e",
                    "Accept-Language": "es-MX"
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Error occurred during the request.');
            }

            const responseData = await response.json();
            const getModifyReserve = responseData.data
            setRsvData(getModifyReserve)
            //if isCorrect is true show reserve
            console.warn("Get Modify RSV", responseData)

            setTimeout(() => { setLoader(false) }, 2500)

        } catch (error) {
            console.error('Error:', error.message);
        }
    }


    useEffect(() => {

        setDisableBtn(isFormEmpty(getNewFecha, getNewHour))
        setTimeout(() => {
            callReserveSaved(localStorage.getItem('idRsv'))
        }, 1500)
        checkIfInputsAreEmpty()

    }, [])

    //componet select hora

    const SelectHora = () => {
        return (
            <select
                id="hour-select"
                name="hora"
                style={{ width: '100%', marginLeft: 10 }}
                onChange={handleHourChange}
                value={getNewHour}
            >
                {

                    JSON.parse(localStorage.getItem('rangoHoras')).map((hour) => (
                        <option key={hour.idHoraDia} value={hour.descripcion} id={hour.idHoraDia}>
                            {hour.descripcion}
                        </option>
                    ))
                }
            </select>
        )

    }

    const handleHourChange = (event) => {

        const rangoDeHoras = JSON.parse(localStorage.getItem('rangoHoras'))
        const selectedHour = event.target.value;
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
        localStorage.setItem('horaReservada', selectedHour)
        console.log("Selected hour:", selectedHour);
        const filterIdHoraDia = rangoDeHoras.filter(hour => hour.descripcion === event.target.value)
        const getIdHoraDia = filterIdHoraDia[0].idHoraDia
        //setSelectedHour(selectedHour)
        setNewHour(selectedHour)
        localStorage.setItem('idHoraDia', getIdHoraDia)
        setNewIdHour(getIdHoraDia)

    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'fecha') {
            localStorage.setItem('fecha', value)
            console.log('feccha', value)
            setNewFecha(value)
        }
    };

    const updateReserva = () => {

        if (getDataRsvd !== null) {
            const objectModify = {
                "idReserva": getIdReserva,
                "idReservaProductoAlquiler": getIdReservaAlquiler,
                "idSede": getIdSede,
                "idHoraDia": getNewIdHour,
                "fecha": getNewFecha,
                "cantidadPersonas": getCantidadPersonas,
                "idProductoAlquiler": getIdProductoAlquiler
            }

            console.log('objectModify', objectModify)
            //send endpoint this object to modify reserve
            setModifyReserve(objectModify)
        }

    }

    //Check if date or hoour are empty
    const checkIfInputsAreEmpty = () => {


        // Check if the inputs are empty
        if (getNewFecha && getNewHour) {
            console.log("One or more fields are empty!");
            setDisableBtn(false)
        } else {
            console.log("All fields are filled!");
        }
    }

    return (
        <div>

            {
                console.log('getNombre', getNombre, "getFecha", getFecha, "getCantidad", getCantidad)
            }

            <HeaderLogoBoliche />
            {
                warningMsg
                    ? <WinTiloPayPOPUP
                        message={getMsgConsult}
                        stateValidate={getInfoDataRsv}
                        getDataRsvd={getRsvData} />
                    : null
            }

            <div className="form-container" style={{ marginTop: 20 }}>
                <div className="form">
                    <Typography variant='h5'>
                        Modificar reserva
                    </Typography>
                    <TextField
                        name="cliente"
                        label="Cliente"
                        value={getNombre}
                        fullWidth
                        margin="normal"
                        disabled
                        variant="filled"
                    />

                    <TextField
                        name="fecha"
                        label="Fecha"
                        value={getFecha}
                        fullWidth
                        margin="normal"
                        disabled
                        variant="filled"
                    />

                    <TextField
                        name="hora"
                        label="Hora"
                        value={getHora}
                        fullWidth
                        margin="normal"
                        disabled
                        variant="filled"
                    />

                    <TextField
                        name="personas"
                        label="Numero de Personas"
                        value={getCantidad}
                        fullWidth
                        margin="normal"
                        disabled
                        variant="filled"
                    />

                    <Divider>Seleccione la nueva fecha y hora</Divider>
                    <br />

                    <div className="input-field">
                        <input value={getNewFecha}
                            type="date"
                            id="fecha"
                            name="fecha"
                            onChange={handleInputChange} />
                    </div>

                    <div className="input-field" name="hora">
                        {JSON.parse(localStorage.getItem('rangoHoras')) !== null ? <SelectHora /> : null}
                    </div>


                    <button
                        variant="contained"
                        className="btnSend"
                        onClick={handleSubmit}
                        disabled={formEmptyStatus}
                    >
                        Siguiente
                    </button>
                    {/* <button onClick={updateReserva}>SAVE</button> */}
                </div>
            </div>
        </div>

    );
};

export default ModifyReserva;