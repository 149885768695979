import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Card,
    CardContent,
    Typography
} from '@material-ui/core';

import {
    DateRange,
    LocationCity,
    AccessTime,
    Groups3Rounded,
    AccountCircleSharp,
    LocalPhoneSharp,
    MarkEmailRead,
    PaymentSharp,
    Groups2,
    AddCardSharp

} from '@mui/icons-material';
import { currentExchangeColon } from '../api/api-request';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',


    },
    containerGetProducts: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#f0f0f0'

    },
    card: {
        width: 350,
        margin: theme.spacing(1),
        flexDirection: 'column',
        alignItems: 'center',
    },
}));


const DisplayReserva = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Card className={classes.card}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', textAlign: 'initial' }}>
                    <Typography variant="h6" component="h3" gutterBottom >
                        <AccountCircleSharp style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Cliente:{" "} <span style={{ fontWeight: 'bolder' }}>{props.name}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        <MarkEmailRead style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Email:{" "} <span style={{ fontWeight: 'bolder' }}>{props.email}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        <LocalPhoneSharp style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Teléfono:{" "}<span style={{ fontWeight: 'bolder' }}>{props.phone}</span>
                    </Typography>
                </CardContent>
            </Card>

            <Card className={classes.card}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', textAlign: 'initial' }}>
                    <Typography variant="h6" component="h3" gutterBottom>
                        <LocationCity style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Sede:{" "} <span style={{ fontWeight: 'bolder' }}>{props.nameSede}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom >
                        <DateRange style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Fecha:{" "} <span style={{ fontWeight: 'bolder' }}>{props.date}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        <AccessTime style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Hora:{" "}<span style={{ fontWeight: 'bolder' }}>{props.hour}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom >
                        <DateRange style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Descripción:{" "} <span style={{ fontWeight: 'bolder' }}>{props.description}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        <Groups3Rounded style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Número de Personas:{" "}<span style={{ fontWeight: 'bolder' }}>{props.persons}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        <Groups3Rounded style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Código de reservación:{" "}<span style={{
                            fontWeight: 'bolder', textAlign: 'center', display: 'flex',
                            flexDirection: 'column',
                            fontSize: 31,
                            color: '#4e2c72'
                        }}>{props.codigoReserva}</span>
                    </Typography>
                </CardContent>
            </Card>

            <Card className={classes.card}>
                <Typography variant="h6" component="h3" gutterBottom>
                    <PaymentSharp style={{ fontSize: 22, color: '#1d6a9f' }} />
                    {" "}Precio:{" "} <span style={{ fontWeight: 'bolder' }}>{'₡'}{currentExchangeColon(parseInt(props.precio))}</span>
                </Typography>
                <Typography variant="h6" component="h3" gutterBottom>
                    <Groups2 style={{ fontSize: 22, color: '#1d6a9f' }} />
                    {" "}Cantidad Reservada:{" "}<span style={{ fontWeight: 'bolder' }}>{props.cantidadMaximaPersonas}</span>
                </Typography>
                <Typography style={{ backgroundColor: '#dbd9df', color: '#000' }} variant="h6" component="h3" gutterBottom>
                    <span style={{ fontWeight: '400' }}>{" "}Sub-Total:{" "}{"₡"}{currentExchangeColon(parseInt(props.subTotal))}</span>
                </Typography>
                <Typography style={{ backgroundColor: '#e2d8ec', color: '#000' }} variant="h6" component="h3" gutterBottom>
                    <span style={{ fontWeight: '400' }}>{" "}Total Comisiones:{" "}{"₡"}{currentExchangeColon(parseInt(props.totalComisiones))}</span>
                </Typography>
                <Typography style={{ backgroundColor: '#4e2c72', color: '#ffffff' }} variant="h6" component="h3" gutterBottom>
                    <AddCardSharp style={{ fontSize: 18, }} />
                    <span style={{ fontWeight: 'bolder' }}>{" "}TOTAL:{" "}{"₡"}{currentExchangeColon(parseInt(props.precio))}</span>
                </Typography>
            </Card>

        </div>

    );
};

export default DisplayReserva