import React, { useEffect, useState } from "react";
import ImageHome from '../components/Image';
import DentImg from '../images/logoBolDent.png';
import CariariImg from '../images/logoBolCariari.png';
import EscazuImg from '../images/logoBolMetropoli.png';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import '../sass/home.scss'
import MenuHome from '../components/MenuHome';
import MenuHomeMobile from '../components/MenuHomeMobile';
import LogoBolera from '../images/logo-boliche-cr.png'
import { useMediaQuery } from '@mui/material';
import json2mq from 'json2mq';


function GetResizeResponsive() {
    const matches = useMediaQuery(
        json2mq({
            minWidth: 1024,
        }),
    );

    return (
        <>
            {matches ? <MenuHome /> : <MenuHomeMobile />}
        </>

    )
}

function Home() {


    localStorage.clear();

    useEffect(() => {
        document.body.style.backgroundColor = "#000";
    }, [])

    return (
        <Grid container spacing={2} >
            <Grid item xs={12} className='topBar'>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <img
                            width="300"
                            height="127"
                            src={LogoBolera}
                            sizes="(max-width: 300px) 100vw, 300px" />
                    </Grid>
                    <Grid item xs={7}>
                        <GetResizeResponsive />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className="containerImages" style={{ backgroundColor: 'black', paddingBottom: 50 }}>
                <Grid container spacing={2} style={{ marginTop: 30 }}>
                    <Grid item xs={12} md={4}>
                        <Link to="/main/1">
                            <img src={CariariImg} alt="Dent" className="imageLink" />
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Link to="/main/3">
                            <img src={DentImg} alt="Dent" className="imageDent" />

                        </Link>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Link to="/main/2">
                            <img src={EscazuImg} alt="Bol Metropoli" className="imageLink" />
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Home
