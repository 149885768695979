import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
});

function ImageHome(props) {
    const classes = useStyles();

    return <img className={classes.image} src={props.src} alt={props.alt} />;
}

export default ImageHome;



