import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonGroup } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        marginTop: 45

    },
    button: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        background: 'none',
        width: 160,
        '&:hover': {
            background: 'none',
            color: '#b6e4fa'
        },
    },
}));

const homeLink = () => {
    window.location.href = '/';
}
const cariariLink = () => {
    window.location.href = 'http://bolichecostarica.com/bol-cariari/'
}

const dentLink = () => {
    window.location.href = 'http://bolichecostarica.com/boliche-dent/'
}

const metroLink = () => {
    window.location.href = 'http://bolichecostarica.com/bol-metropoli/'
}

const aboutUs = () => {
    window.location.href = 'http://bolichecostarica.com/sobre-nosotros/'
}




function MenuHome() {
    const classes = useStyles();
    //=Navigate to Validate PAY
    const navigate = useNavigate();

    const consultar = () => {
        navigate('/consultar')
    }

    return (
        <div className={classes.root}>
            <ButtonGroup variant="text" color="primary">
                <Button className={classes.button} onClick={homeLink}>Inicio</Button>
                <Button className={classes.button} onClick={cariariLink}>Bol Cariari</Button>
                <Button className={classes.button} onClick={dentLink}>Boliche Dent</Button>
                <Button className={classes.button} onClick={metroLink}>Bol Metropoli</Button>
                <Button className={classes.button} onClick={aboutUs}>Sobre Nosotros</Button>
                <Button className={classes.button} onClick={consultar}>Consultar</Button>
            </ButtonGroup>
        </div>
    );
}

export default MenuHome

