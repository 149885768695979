import React from 'react';
import {

    Card,
    CardContent,
    Typography
} from '@material-ui/core';

import {
    DateRange,
    LocationCity,
    AccessTime,
    Groups3Rounded,

} from '@mui/icons-material';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',


    },
    containerGetProducts: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#f0f0f0'

    },
    card: {
        width: 350,
        margin: theme.spacing(1),
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const DisplayReserva = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Card className={classes.card}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', textAlign: 'initial' }}>
                    <Typography variant="h6" component="h3" gutterBottom >
                        <DateRange style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Fecha:{" "} <span style={{ fontWeight: 'bolder' }}>{props.date}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        <LocationCity style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Sede:{" "} <span style={{ fontWeight: 'bolder' }}>{props.nameSede}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        <AccessTime style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Hora:{" "}<span style={{ fontWeight: 'bolder' }}>{props.hours}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        <Groups3Rounded style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Cantidad de Personas:{" "}<span style={{ fontWeight: 'bolder' }}>{props.persons}</span>
                    </Typography>
                </CardContent>
            </Card>
        </div>

    );
};

export default DisplayReserva