import React, { useEffect, useState, useContext } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircleSharp, PaymentsSharp, DescriptionSharp, } from '@mui/icons-material';
import './style.css'
import { currentExchangeColon } from '../api/api-request'
import { DataContext } from '../../store/DataContext';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    containerGetProducts: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#f0f0f0'

    },
    card: {
        width: 350,
        margin: theme.spacing(1),
        flexDirection: 'column',
        alignItems: 'center',
        float: 'left'
    },
}));


const GetProductsRent = () => {
    //DataProvider
    const { formDataUser, updateFormData, dateHour, updateFormDateHour, data, setData } = useContext(DataContext);


    const [selectedButton, setSelectedButton] = useState(null);

    const handleButtonClick = (id) => {
        setSelectedButton(id);
        localStorage.setItem('idProductoAlquiler', id);
        setData(id)
    };

    const classes = useStyles();

    useEffect(() => {

        console.log('LOADBtn', localStorage.getItem('idProductoAlquiler'))
        setSelectedButton(parseInt(localStorage.getItem('idProductoAlquiler')));

    }, [])

    useEffect(() => {
        // console.log('LOAD COMPONENNT Btn: ', selectedButton)
        console.log('LoadGetBtnId=======', localStorage.getItem('idProductoAlquiler'))



    }, [
        JSON.parse(localStorage.getItem('descriptPromoRent'))
    ])

    return (
        JSON.parse(localStorage.getItem('descriptPromoRent')) !== null
            ? JSON.parse(localStorage.getItem('descriptPromoRent')).map((e) => {
                return (
                    <>
                        <div className="grid-item" id={e.idProductoAlquiler} key={e.id}>
                            <Card className={classes.card}  >
                                {console.log('selectedButton: ', selectedButton)}
                                <CardContent
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'initial',

                                    }}
                                    optionUnselect
                                    className={selectedButton === e.idProductoAlquiler ? 'optionSelect' : 'optionUnselect'}
                                >
                                    <Typography variant="h6" component="h3" gutterBottom>
                                        <CheckCircleSharp style={{ fontSize: 22, color: '#1d6a9f' }} />
                                        <span style={{ fontWeight: 'bolder' }}>{" "}Seleccione Opción:{" "}</span>
                                    </Typography>

                                    <Typography variant="h6" component="h3" gutterBottom>
                                        <PaymentsSharp style={{ fontSize: 22, color: '#1d6a9f' }} />
                                        {" "}Precio:{" "} <span style={{ fontWeight: 'bolder' }}>{"₡"}{currentExchangeColon(e.precio)}</span>
                                    </Typography>

                                    <Typography variant="h6" component="h3" gutterBottom>
                                        <DescriptionSharp style={{ fontSize: 22, color: '#1d6a9f' }} />
                                        {" "}Descripción:{" "} {e.descripcion}
                                    </Typography>

                                    <button
                                        key={e.idProductoAlquiler}
                                        id={e.idProductoAlquiler}
                                        className={selectedButton === e.idProductoAlquiler ? 'selected' : 'unselected'}
                                        onClick={() => handleButtonClick(e.idProductoAlquiler)}
                                    >
                                        {
                                            selectedButton === e.idProductoAlquiler ? 'Seleccionado' : 'Seleccionar'
                                        }
                                    </button>
                                </CardContent>
                            </Card>
                        </div>

                    </>

                )
            }) : null
    );
};



export default GetProductsRent;
