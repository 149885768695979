import React, { useEffect, useState, useContext } from 'react';
import {
    TextField,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import './style.css'; // Import the CSS file
import { endPointAPiBoleraApp, getConfirmationData } from '../api/api-request'
import HeaderLogoBoliche from '../header';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../store/DataContext';


const msgPopupWarning = `Se abrirá una ventana con el provedor de pagos, 
                        luego de realizar el pago cierre la ventana emergente, 
                        y compruebe que el pago haya sido registrado, 
                        posterior a eso puede confirmar su reserva`

const TiloPayForm = () => {
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        direccion: '',
        direccion2: '',
        ciudad: '',
        provincia: '',
        codigoPostal: '',
        pais: '',
    });

    const { formDataUser, updateFormData } = useContext(DataContext);

    const [dataSendTiloPay, setDataSendTiloPay] = useState([])
    const [openPopup, setOpenPopup] = useState(false);
    const [warningMsg, setWarningMsg] = useState(false);
    const [openWarningPopup, setWarningPopup] = useState(false);
    const [urlTiloPay, setUrlTiloPay] = useState('')
    const [loader, setLoader] = useState(false)
    //=Navigate to Validate PAY
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        console.log('formData.pais', formData.pais)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // You can perform your form submission logic here
        console.log(formData);
        // Reset the form
        setFormData({
            nombre: '',
            apellido: '',
            direccion: '',
            direccion2: '',
            ciudad: '',
            provincia: '',
            codigoPostal: '',
            pais: '',
        });
        // Perform form validation

        setDataSendTiloPay({
            idReserva: localStorage.getItem('idRsv'),
            firstName: formData.nombre,
            lastName: formData.apellido,
            address: formData.direccion,
            address2: formData.direccion2,
            city: formData.ciudad,
            state: formData.provincia,
            zipPostCode: formData.codigoPostal,
            idPais: formData.pais
        })

        console.log('dataSendTiloPay', dataSendTiloPay);

        if (!isFormEmpty) {
            //Open Warning Window
            openWarningPopupWin()

        }

    };

    //Save list country
    const [getCountryList, setCountry] = useState([])

    //evaluate if form is empty
    const isFormEmpty = Object.values(formData).some((value) => String(value).trim() === '');

    console.log('isFormEmpty', isFormEmpty)

    //Connection to API
    const urlTiloPayEndPoint = endPointAPiBoleraApp + 'api/External/GuardarReservaPagoPersona'
    const getLinkTiloPay = async (data = {}) => {
        try {
            const response = await fetch(urlTiloPayEndPoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "AuthorizationToken": "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e",
                    "Accept-Language": "es-MX"
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Error occurred during the request.');
            }

            const responseData = await response.json();
            console.log('Response get SEDE:', responseData);
            setUrlTiloPay(responseData.data)
            setLoader(false)

            //localStorage.setItem('tiloPayCorrect', responseData.isCorrect)
        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    //Get list country
    const getListCountry = async () => {
        try {
            const response = await fetch(`${endPointAPiBoleraApp}api/External/GetPaisList`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "AuthorizationToken": "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e",
                    "Accept-Language": "es-MX"
                }
            });

            if (!response.ok) {
                throw new Error('Error occurred during the request.');
            }

            const responseData = await response.json();
            console.log('Response get Country', responseData);
            setCountry(responseData.data)

        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    useEffect(() => {
        getListCountry()
        console.log('ListCountry: ', getCountryList)
    }, [])
    const handleOpenPopup = () => {
        setOpenPopup(true)
        setWarningPopup(false)
        setWarningMsg(true)
        //Loader setup
        setLoader(true)
        //Send Post for get TyloPay Link
        getLinkTiloPay(dataSendTiloPay);
    }

    const handleClosePopup = () => {
        setOpenPopup(false)
        setTimeout(() => {
            navigate('/validate')
        }, 500)
    }

    const openWarningPopupWin = () => {
        setWarningPopup(true)
    }

    const WinTiloPayPOPUP = () => {
        return (
            <Dialog open={openPopup} maxWidth="md" fullWidth>
                {
                    !loader
                        ?
                        <>
                            <DialogContent>
                                <iframe src={urlTiloPay} title="TiloPay" width="100%" height="550px" frameBorder={0} />
                            </DialogContent>
                            <DialogActions>
                                <button onClick={handleClosePopup} className="btnSend">
                                    Cerrar {'x'}
                                </button>
                            </DialogActions>
                        </>
                        : <div style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <CircularProgress style={{ color: 'purple' }} />
                            <Typography >
                                Cargando...
                            </Typography>

                        </div>
                }
            </Dialog>
        )
    }

    console.log('DATA USER', formDataUser)

    return (
        <div>
            <HeaderLogoBoliche />

            <Dialog open={openWarningPopup} maxWidth="md" fullWidth>
                <DialogContent>
                    <Typography>
                        {msgPopupWarning}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOpenPopup} color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

            {
                warningMsg ? <WinTiloPayPOPUP /> : null
            }

            <div className="form-container" style={{ marginTop: 20 }}> {/* Added container div */}

                <div className="form">
                    <Typography variant='h5'>
                        Datos para el pago
                    </Typography>
                    <TextField
                        name="nombre"
                        label="Nombre"
                        value={formData.nombre}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                        error={formData.nombre === '' ? true : false}
                    />
                    <TextField
                        name="apellido"
                        label="Apellido"
                        value={formData.apellido}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                        error={formData.apellido === '' ? true : false}
                    />
                    <TextField
                        name="direccion"
                        label="Direccion"
                        value={formData.direccion}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                        error={formData.direccion === '' ? true : false}
                    />
                    <TextField
                        name="direccion2"
                        label="Direccion 2"
                        value={formData.direccion2}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={formData.direccion2 === '' ? true : false}
                    />
                    <TextField
                        name="ciudad"
                        label="Ciudad"
                        value={formData.ciudad}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                        error={formData.ciudad === '' ? true : false}
                    />
                    <TextField
                        name="provincia"
                        label="Provincia"
                        value={formData.provincia}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                        error={formData.provincia === '' ? true : false}
                    />
                    <TextField
                        name="codigoPostal"
                        label="Codigo Postal"
                        value={formData.codigoPostal}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                        error={formData.codigoPostal === '' ? true : false}
                    />

                    <FormControl fullWidth margin="normal" required error={formData.pais !== 0}>
                        <InputLabel>Pais</InputLabel>
                        <Select
                            name="pais"
                            label="Pais"
                            fullWidth
                            value={formData.pais || 0}
                            onChange={handleChange}
                        >
                            <MenuItem value={0}>
                                <em>Seleccione pais</em>
                            </MenuItem>
                            {getCountryList.map(country => (
                                <MenuItem key={country.idPais} value={country.idPais}>
                                    {country.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>



                    <button
                        variant="contained"
                        className="btnSend"
                        onClick={handleSubmit}
                        disabled={isFormEmpty}
                    >
                        Siguiente
                    </button>
                </div>
            </div>
        </div>

    );
};

export default TiloPayForm;



