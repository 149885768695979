import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import '../sass/internal-pages.scss'
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';

import PickerSelector from './PickerData/PickerSelector';
import { display, padding } from '@mui/system';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        marginBottom: theme.spacing(3),
    },
    centerDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        paddingLeft: 10
    }
}));

function TemplateINternalPage(props) {
    const classes = useStyles();

    return (
        // <div style={{ width: 500, backgroundColor: 'red', margin: '0px auto' }}/>
        <Box className={classes.centerDiv}>
            <div>
                <Typography variant="h2" component="h1" className="title">
                    {props.title}
                </Typography>
            </div>
            <div style={{ width: '100%' }}>
                <PickerSelector />

            </div>
            <div>
                {props.content}
            </div>
            <div>
                <Link className='links' to="/">
                    {" "} Volver al inicio
                </Link>
            </div>
        </Box>

    );
}




export default TemplateINternalPage;
