import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles({
    root: {
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        height: '9vh',
    },
    countDown: {
        fontSize: 35,
        fontWeight: 'bold',
        color: '#ff1307',
    },
});

const Countdown = () => {
    const [count, setCount] = useState(45);
    const classes = useStyles();

    useEffect(() => {
        const timer = setInterval(() => {
            setCount(count => count - 1);
        }, 1000);

        return () => {
            clearInterval(timer);

            console.log('Time UP')
        };
    }, []);

    return (
        <div className={classes.root}>
            {
                count == 0 ? alert('El tiempo se agoto') : null
            }
            <Zoom in={count > 0}>
                <Typography variant="h1" className={classes.countDown}>
                    {count}
                </Typography>
            </Zoom>
        </div>
    );
};

export default Countdown;
