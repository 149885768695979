import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import CreditCard from '../credit-card';
import RequestTiloPay from '../TiloPayForm';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { currentExchangeColon } from '../api/api-request';


import {
    Button,
    Card,
    CardContent,
    Typography
} from '@material-ui/core';

import {
    DateRange,
    LocationCity,
    AccessTime,
    Groups3Rounded,
    AccountCircleSharp,
    LocalPhoneSharp,
    MarkEmailRead,
    PaymentSharp,
    Groups2,
    AddCardSharp

} from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    size='small'
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',


    },
    containerGetProducts: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#f0f0f0'

    },
    card: {
        width: 350,
        margin: theme.spacing(1),
        flexDirection: 'column',
        alignItems: 'center',
    },
}));


const DisplayReserva = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Card className={classes.card}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', textAlign: 'initial' }}>
                    <Typography variant="h6" component="h3" gutterBottom >
                        <AccountCircleSharp style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Cliente:{" "} <span style={{ fontWeight: 'bolder' }}>{props.name}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        <MarkEmailRead style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Email:{" "} <span style={{ fontWeight: 'bolder' }}>{props.email}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        <LocalPhoneSharp style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Teléfono:{" "}<span style={{ fontWeight: 'bolder' }}>{props.phone}</span>
                    </Typography>
                </CardContent>
            </Card>

            <Card className={classes.card}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', textAlign: 'initial' }}>
                    <Typography variant="h6" component="h3" gutterBottom>
                        <LocationCity style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Sede:{" "} <span style={{ fontWeight: 'bolder' }}>{props.nameSede}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom >
                        <DateRange style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Fecha:{" "} <span style={{ fontWeight: 'bolder' }}>{props.date}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        <AccessTime style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Hora:{" "}<span style={{ fontWeight: 'bolder' }}>{props.hour}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom >
                        <DateRange style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Descripción:{" "} <span style={{ fontWeight: 'bolder' }}>{props.description}</span>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        <Groups3Rounded style={{ fontSize: 22, color: '#1d6a9f' }} />
                        {" "}Número de Personas:{" "}<span style={{ fontWeight: 'bolder' }}>{props.persons}</span>
                    </Typography>
                </CardContent>
            </Card>

            <Card className={classes.card}>
                <Typography variant="h6" component="h3" gutterBottom>
                    <PaymentSharp style={{ fontSize: 22, color: '#1d6a9f' }} />
                    {" "}Precio:{" "} <span style={{ fontWeight: 'bolder' }}>{'₡'}{currentExchangeColon(parseInt(props.precio))}</span>
                </Typography>
                <Typography variant="h6" component="h3" gutterBottom>
                    <Groups2 style={{ fontSize: 22, color: '#1d6a9f' }} />
                    {" "}Cantidad Reservada:{" "}<span style={{ fontWeight: 'bolder' }}>{props.cantidadMaximaPersonas}</span>
                </Typography>
                <Typography style={{ backgroundColor: '#dbd9df', color: '#000' }} variant="h6" component="h3" gutterBottom>
                    <span style={{ fontWeight: '400' }}>{" "}Sub-Total:{" "}{"₡"}{currentExchangeColon(parseInt(props.subTotal))}</span>
                </Typography>
                <Typography style={{ backgroundColor: '#e2d8ec', color: '#000' }} variant="h6" component="h3" gutterBottom>
                    <span style={{ fontWeight: '400' }}>{" "}Cargo por reservación{" "}{"₡"}{currentExchangeColon(parseInt(props.totalComisiones))}</span>
                </Typography>
                <Typography style={{ backgroundColor: '#4e2c72', color: '#ffffff' }} variant="h6" component="h3" gutterBottom>
                    <AddCardSharp style={{ fontSize: 18, }} />
                    <span style={{ fontWeight: 'bolder' }}>{" "}TOTAL:{" "}{"₡"}{currentExchangeColon(parseInt(props.precio))}</span>
                </Typography>
            </Card>

        </div>

    );
};


export default function CheckReserve(props) {
    const [open, setOpen] = React.useState(false);
    const [showCreditCard, setShowCreditCard] = useState(false)
    //== INFO DATA USER
    const [dataUser, setDataUser] = useState([])
    //== COnvert in colones curretn MOney
    const [priceCol, setPriceCol] = useState('')
    const [getRsvObject, setRsvObject] = useState([])
    //=== INFO Promo Rent 
    const [dataPromoRent, setDataPromoRent] = useState([])

    const [loader, setLoader] = useState(false)

    //=Navigate to TiloPay
    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const getInfoDataReserved = () => {
        const getInfoData = JSON.parse(localStorage.getItem('dataUserRsv'))
        setDataUser(getInfoData)
        const getInfoRentPromos = JSON.parse(localStorage.getItem('descriptPromoRent'))

        let toNumber = parseInt(localStorage.getItem('idProductoAlquiler'))
        console.log('ID GET', toNumber)
        console.log('getInfoRentPromos', getInfoRentPromos.idProductoAlquiler)
        setDataPromoRent(getInfoRentPromos)
        let convertToString = localStorage.getItem('rsvTotal').toString()
        const formattedAmount = convertToString.toLocaleString('es-CR', {
            style: 'currency',
            currency: 'CRC',
        });


        //console.log('formattedAmount', formattedAmount);
        setPriceCol(formattedAmount)

    }

    useEffect(() => {
        getInfoDataReserved()
        handleClickOpen()
        setLoader(true)
        console.log('Load component', dataPromoRent)
    }, [])


    useEffect(() => {
        setRsvObject(JSON.parse(localStorage.getItem('rsvSaved')))
        console.log('LOAD DATA RESERVED', getRsvObject)
        setTimeout(() => {
            setLoader(false)
        }, 2500)

    }, [localStorage.getItem('rsvSaved')])

    const btnCreditCard = () => {
        setShowCreditCard(true)
        navigate('/tilopay');

    }

    const DisplayReservaComplete = () => {
        return (
            <>
                <DialogContent dividers>
                    <DisplayReserva
                        name={dataUser.nombre}
                        email={dataUser.email}
                        phone={dataUser.telefono}
                        nameSede={localStorage.getItem('sede')}
                        date={localStorage.getItem('fecha')}
                        hour={localStorage.getItem('horaReservada')}
                        persons={localStorage.getItem('cantidad')}
                        codigoReserva={localStorage.getItem('rsvCode')}
                        description={localStorage.getItem('rsvDescript')}
                        cantidadMaximaPersonas={dataPromoRent.cantidadMaximaPersonas}
                        subTotal={localStorage.getItem('subTotal')}
                        totalComisiones={localStorage.getItem('totalComisiones')}
                        precio={priceCol}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{ margin: '0 auto', backgroundColor: '#4e2c72', color: '#fff' }}

                        size='large'
                        autoFocus
                        onClick={btnCreditCard}>
                        Siguiente
                    </Button>
                </DialogActions>
            </>
        )
    }

    const DisplayGrandTotal = () => {

        return (
            <>
                {
                    loader ? <CircularProgress /> : <DisplayReservaComplete />
                }

            </>
        )
    }

    return (
        <>
            {showCreditCard == true ? <RequestTiloPay /> : <DisplayGrandTotal />}
        </>
    );
}