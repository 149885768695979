import React from "react";
import CreditCard from "../components/credit-card";

const Card = () => {
    return (
        <div>
            <CreditCard />
        </div>
    )
}

export default Card