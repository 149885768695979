
export const endPointAPiBoleraApp = "https://reservasapi.bolichecostarica.com/"


export async function getSedeId(sedeID) {
    try {
        const response = await fetch(`https://reservasapi.bolichecostarica.com/api/External/GetSede/${sedeID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "AuthorizationToken": "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e",
                "Accept-Language": "es-MX"
            }
        });
        const data = await response.json();
        console.log('GetSedeOBJ', data);
        console.log('idSede', data.data.idSede, "Nombre:", data.data.nombre);
        localStorage.setItem('sede', data.data.nombre);
        return data
    } catch (error) {
        console.error(error);
    }
}


// Get Alquiler Productos
export const getDataProducts = async () => {

    const dataReserva = JSON.parse(localStorage.getItem('rsv'))

    const rentProducts = {
        idSede: dataReserva.idSede,
        idHoraDia: dataReserva.idHoraDia,//hourDay,
        fecha: dataReserva.fecha,
        cantidadPersonas: dataReserva.cantidadPersonas
    };

    console.log('PAYLOAD OBJ', rentProducts)

    try {
        const response = await fetch("https://reservasapi.bolichecostarica.com/api/External/GetProductosAlquiler", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "AuthorizationToken": "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e",
                "Accept-Language": "es-MX"
            },
            body: JSON.stringify(rentProducts)
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        //console.log('DATA ALQUILER', data)
        //console.log('++++++Descript Prod rent: ', JSON.stringify(data.data))
        localStorage.setItem('descriptPromoRent', JSON.stringify(data.data))
        //console.log('isCorrect', data.isCorrect, "Message:", data.message)
        localStorage.setItem('isCorrect', JSON.stringify(data.isCorrect))
        localStorage.setItem('msgFromAPi', JSON.stringify(data.message))

    } catch (error) {

        console.log('Error', error)
        localStorage.setItem('msgFromAPi', JSON.stringify(error))

    }
};


//==== SAVE RESERVE =============================================

export const saveReserveData = async () => {

    const dataReserva = JSON.parse(localStorage.getItem('dataUserRsv'))

    const objectForSaveReserve = {
        "idReserva": 0,
        "idReservaProductoAlquiler": 0,
        "idSede": localStorage.getItem('sedeID'),
        "idHoraDia": localStorage.getItem('idHoraDia'),
        "fecha": localStorage.getItem('fecha'),
        "cantidadPersonas": localStorage.getItem('cantidad'),
        "idProductoAlquiler": parseInt(localStorage.getItem('idProductoAlquiler')),
        "idReservaContacto": 0,
        "nombre": dataReserva.nombre,
        "primerApellido": dataReserva.apellido,
        "email": dataReserva.email,
        "telefono": dataReserva.telefono
    };

    //console.log('{ OBJ Save Reserved }:', objectForSaveReserve)


    try {
        const response = await fetch(`${endPointAPiBoleraApp}api/External/GuardarReserva`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "AuthorizationToken": "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e",
                "Accept-Language": "es-MX"
            },
            body: JSON.stringify(objectForSaveReserve)
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        //console.log('Reserve saved: ->', data)
        //console.log('rsvSaved: ', JSON.stringify(data.data))
        localStorage.setItem('rsvSaved', JSON.stringify(data.data))
        //sub total
        localStorage.setItem('subTotal', JSON.stringify(data.data.subTotal))
        //Comissions Payed
        localStorage.setItem('totalComisiones', JSON.stringify(data.data.totalComisiones))
        //set Total
        localStorage.setItem('rsvTotal', JSON.stringify(data.data.total))

        //set Descript
        localStorage.setItem('rsvDescript', JSON.stringify(data.data.reservaProductoAlquiler.descripcion))
        //idRerserva
        localStorage.setItem('idRsv', JSON.stringify(data.data.idReserva))

    } catch (error) {
        console.log('Error', error)
    }
};

export const currentExchangeColon = (amount) => {
    const formattedAmount = amount.toLocaleString();
    return formattedAmount
}
