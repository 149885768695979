import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import '../../sass/general.scss'


const themeLight = createTheme({
    palette: {
        background: {
            default: "#e4f0e2"
        }
    }
});

const themeDark = createTheme({
    palette: {
        background: {
            default: "#222222"
        },
        text: {
            primary: "#ffffff"
        }
    }
});

const PickerSelector = () => {

    const [hours, setHours] = useState([]);
    const [selectedHour, setSelectedHour] = useState('');
    const myHour = []

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch('https://reservasapi.bolichecostarica.com/api/External/GetHoras?IdSede=1');
                const data = await response.json();
                myHour.push(data.data)
                console.log('Hours', myHour[0])
                setHours(myHour[0]);
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, []);

    const handleHourChange = (event) => {
        setSelectedHour(event.target.value);
    };


    const [formData, setFormData] = useState({
        date: '',
        hour: '',
        quantity: '',
        name: '',
        lastName: '',
        email: ''
    });

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // save data to json file and create cookie
        alert('DATA SAVED: ' + formData)
        console.log('DATA SAVED: ', formData)
    };

    const [light, setLight] = React.useState(true);

    return (

        <form onSubmit={handleSubmit} className='dataPicker'>
            <TextField
                id="date"
                name="date"
                // label="Date"
                type="date"
                label="Standard" variant="standard"
                value={formData.date}
                onChange={handleChange}
                required
            />
            {/* <TextField
                id="time"
                name="hour"
                label="Time"
                type="time"
                value={formData.hour}
                onChange={handleChange}
            /> */}
            <FormControl>
                <InputLabel id="hour-select-label">Select Hour</InputLabel>
                <Select
                    labelId="hour-select-label"
                    id="hour-select"
                    value={selectedHour}
                    onChange={handleHourChange}
                >
                    {hours.map((hour) => (
                        <MenuItem key={hour.idHoraDia} value={hour.descripcion}>
                            {hour.descripcion}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                id="quantity"
                name="quantity"
                label="Quantity"
                type="number"
                inputProps={{ min: 1, max: 12 }}
                value={formData.quantity}
                onChange={handleChange}
            />
            <TextField
                id="name"
                name="name"
                label="Name"
                value={formData.name}
                onChange={handleChange}
            />
            <TextField
                id="lastName"
                name="lastName"
                label="Last Name"
                value={formData.lastName}
                onChange={handleChange}
            />
            <TextField
                id="email"
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
            />
            <Button type="submit" variant="contained" className='btnColor'>
                Submit
            </Button>
        </form>

        // <>
        //     <ThemeProvider ThemeProvider theme={light ? themeLight : themeDark} >
        //         <CssBaseline />
        //         <Button onClick={() => setLight((prev) => !prev)}>Toggle Theme</Button>
        //     </ThemeProvider >
        // </>
    );
};

export default PickerSelector;
