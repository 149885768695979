import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './style.css';
import HeaderLogoBoliche from '../header';
import { CircularProgress } from '@mui/material';
import SuccessReservation from '../../pages/SuccessReservation'
import { useNavigate } from 'react-router-dom';
//import { getConfirmationData } from '../api/api-request'
import { Alert, Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { endPointAPiBoleraApp } from '../api/api-request'

const textValidation = `Luego de realizar el pago con el proveedor
                        de pagos, por favor valide la transacción 
                        presionando el botón de Validar Pago.
                        Puede intentar validar el pago las veces que 
                        lo necesite.`

const textValidation2 = `Si desea corregir o modificar el pago
                         por favor haga click en Regresar y llene el formulario
                         de nuevo`

const textBtnValidar = `Validar Pago`

const textBtnForm = `Regresar`

const textTitle = `Validación del Pago`

const TiloPayForm = () => {

    const [loader, setLoader] = useState(false)
    const [reponseValidate, setRespValidate] = useState(false)
    //Save object after validate
    const [objReserv, setObjReserv] = useState([])
    //=Navigate to Validate PAY
    const navigate = useNavigate();
    //save complete object reserve
    const [getDataRsvComplete, setDataRsvComplete] = useState([])

    const backToForm = () => {
        navigate('/tilopay');
    }

    const goToConsulta = () => {
        navigate('/consultar');
    }

    const validate = () => {


        if (localStorage.getItem('idRsv') !== null) {
            setLoader(true)
            getConfirmationData()

        }

        setTimeout(() => {
            setLoader(false)
            setRespValidate(true)
        }, 4000)
    }

    const getConfirmationData = async () => {
        const urlValidate = `https://reservasapi.bolichecostarica.com/api/External/ValidarPagoReserva/${localStorage.getItem('idRsv')}`
        try {
            const response = await fetch(urlValidate, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "AuthorizationToken": "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e",
                    "Accept-Language": "es-MX"
                }
            });
            if (!response.ok) {
                throw new Error('Error occurred during the request.');
            }
            const data = await response.json();
            setObjReserv(data)
            //call get Reserve
            callReserveSaved(localStorage.getItem('idRsv'))

            // Handle the received data or update the state as needed
        } catch (error) {
            console.error('Error:', error.message);
        }
    }


    const callReserveSaved = async (getIdReserve) => {

        const endPointGetReserve = `${endPointAPiBoleraApp}/api/External/GetDetailsById/` + getIdReserve

        try {
            const response = await fetch(endPointGetReserve, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'AuthorizationToken': 'e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e',
                    'Accept-Language': 'es-MX'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const getData = await response.json();
            setDataRsvComplete(getData)
            // Use the getData
            console.warn('Get Data rsv', getData.data.reservaConfirmacion);
            localStorage.setItem('rsvCode', getData.data.reservaConfirmacion.codigoConfirmacion)
            localStorage.setItem('rsvComplete', JSON.stringify(getData))

        } catch (error) {
            // Handle the error
            console.error('There was an error!', error);
        }

    }




    return (
        <div >
            <HeaderLogoBoliche />
            <div className="form-container" style={{ marginTop: 20 }}> {/* Added container div */}
                <div className="form" style={{ textAlign: 'justify' }}>
                    <Typography variant='h5' align='center' paragraph>{textTitle}</Typography>
                    {
                        !loader
                            ? !reponseValidate ?
                                <>
                                    <Typography paragraph>
                                        {textValidation}
                                    </Typography>

                                    <Typography paragraph>
                                        {textValidation2}
                                    </Typography>
                                </>
                                : <div>

                                    {
                                        objReserv.isCorrect
                                            ?
                                            <>
                                                <SuccessReservation codeConfirm={getDataRsvComplete.data.reservaConfirmacion.codigoConfirmacion} />
                                                <Alert severity="success">Su pago fue exitoso! — Gracias!</Alert>
                                            </>

                                            :
                                            <Alert severity="error">Error en el pago, favor revisar!</Alert>
                                    }


                                </div>
                            :
                            <div style={{ textAlign: 'center' }}>
                                <CircularProgress />
                                <Typography paragraph>
                                    Cargando...
                                </Typography>
                            </div>
                    }

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>

                        {
                            objReserv.isCorrect
                                ?
                                <>
                                    <button
                                        variant="contained"
                                        className="btnsValidate"
                                        onClick={goToConsulta}
                                    >
                                        <HomeIcon /> {'Consultar su reserva'}
                                    </button>
                                    {/* <a href="/consultar">
                                        <HomeIcon /> {'Consultar su reserva'}
                                    </a> */}
                                </>
                                :
                                <>
                                    <button
                                        variant="contained"
                                        className="btnsValidate"
                                        onClick={backToForm}
                                    >
                                        {textBtnForm}
                                    </button>
                                    <button
                                        variant="contained"
                                        className="btnsValidate"
                                        onClick={validate}
                                    >
                                        {textBtnValidar}
                                    </button>
                                </>
                        }


                    </div>

                </div>
            </div>
        </div>

    );
};

export default TiloPayForm;



