// 1. Create a new context using React.createContext()
import React, { createContext, useState } from 'react';
import { DataContext } from './DataContext';

// 2. Define a state object in the context provider component
const DataProvider = ({ children }) => {
    const [formDataUser, setFormData] = useState();

    const [dateHour, setDateHour] = useState();

    // 3. Create a function that updates the state object
    const updateFormData = (key, value) => {
        setFormData({
            ...formDataUser,
            [key]: value,
        });
    };

    const updateFormDateHour = (key, value) => {
        setDateHour({
            ...dateHour,
            [key]: value,
        });
    };

    const [data, setData] = useState('');

    return (
        // 4. Wrap your application or component tree with the context provider component
        <DataContext.Provider value={{ data, setData, formDataUser, updateFormData, dateHour, updateFormDateHour }}>
            {children}
        </DataContext.Provider>
    );
};

export default DataProvider;
