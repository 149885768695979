import React, { useState, useEffect, useContext } from "react";
import { getSedeId, getDataProducts, saveReserveData, endPointAPiBoleraApp } from "../api/api-request";
import DisplayReserva from "./DataReserva";
import GetProductsRent from "./GetProductsRent";
import CheckReserve from "../modal";
import HeaderLogoBoliche from "../header";
import './style.css'
import { CircularProgress } from "@material-ui/core";
import { format } from "crypto-js";
import { DataContext } from "../../store/DataContext";
import { useNavigate } from 'react-router-dom';
import { Alert, AlertTitle, TimePicker, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Apple from '../../images/Apple.png'
import Google from '../../images/GooglePlay.png'



const MultiStepForm = () => {
    const [step, setStep] = useState(1);
    const [submit, setSubmit] = useState(false)
    const [getRangoHorasSede, setRangoHoras] = useState([])
    const [formData, setFormData] = useState({
        fecha: "",
        hora: "",
        cantidad: "",
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
        name: "",
        password: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        descriptPromoRent: ""

    });


    //DataProvider
    const { formDataUser, updateFormData, dateHour, updateFormDateHour, data, setData } = useContext(DataContext);

    const navigate = useNavigate();


    const [createRsv, setCreateRsv] = useState([])

    const [preloadData, setPreloadData] = useState(false)
    const [selectedHour, setSelectedHour] = useState('');
    const [btnStep, setBtnStep] = useState(true);

    //Error Messages
    const [message, setMessage] = useState('')
    //Get Sede
    const [getSedeNameBol, setSedeNameBol] = useState('')
    //Emails are matching
    const [email2, setEmail2] = useState('');
    //Save number of sede
    const [getSedeIdNum, setSedeIdNum] = useState("");
    //Show Menu
    const [getShowMenu, setShowMenu] = useState(false)
    //Show Hour Menu
    const [getHourSelect, setHourSelect] = useState("")
    //show Cantidad Personas
    const [count, setCount] = useState(0);
    const [selectedNumber, setSelectedNumber] = React.useState(0);
    //Save list country
    const [getCountryList, setCountry] = useState([])

    const [getPackages, setPackages] = useState(null)

    const [showModal, setShowModal] = useState(false);

    const handleInputChange = (event) => {

        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));

        updateFormData(name, value)


        if (name === 'fecha') {
            localStorage.setItem('fecha', value)
            setCreateRsv((prevState) => ({
                ...prevState,
                [name]: value
            }));
            getHoursBySede()
        }

        if (name === 'cantidad') {
            localStorage.setItem('cantidad', value)
            setCreateRsv((prevState) => ({
                ...prevState,
                [name]: value
            }));
            //console.log('CANTIDAD::::', value, "createRsv", createRsv)
        }

    };


    //GET PRODUCTS RESERVES ==========================================================

    const getDataProducts2 = async () => {

        const dataReserva = JSON.parse(localStorage.getItem('rsv'))

        const rentProducts = {
            idSede: dataReserva.idSede,
            idHoraDia: dataReserva.idHoraDia,//hourDay,
            fecha: dataReserva.fecha,
            cantidadPersonas: dataReserva.cantidadPersonas
        };

        console.log('PAYLOAD OBJ', rentProducts)

        try {
            const response = await fetch(`${endPointAPiBoleraApp}api/External/GetProductosAlquiler`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "AuthorizationToken": "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e",
                    "Accept-Language": "es-MX"
                },
                body: JSON.stringify(rentProducts)
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            setPackages(data.data)
            //console.log('DATA ALQUILER', data)
            //console.log('++++++Descript Prod rent: ', JSON.stringify(data.data))
            localStorage.setItem('descriptPromoRent', JSON.stringify(data.data))
            //console.log('isCorrect', data.isCorrect, "Message:", data.message)
            localStorage.setItem('isCorrect', JSON.stringify(data.isCorrect))
            localStorage.setItem('msgFromAPi', JSON.stringify(data.message))
            console.log('setPackages', data.data)

        } catch (error) {

            console.log('Error', error)
            localStorage.setItem('msgFromAPi', JSON.stringify(error))

        }
    };

    // =====================================================================


    const handleNextStep = () => {

        setStep(step + 1);
        //console.log('step', step)
        if (step == 1) {
            //Get Rent Data OPtions
            const dataObjAlquiler = {
                "idSede": localStorage.getItem('sedeID'),
                "idHoraDia": localStorage.getItem('idHoraDia'),
                "fecha": localStorage.getItem('fecha'),
                "cantidadPersonas": localStorage.getItem('cantidad')
            }

            localStorage.setItem('rsv', JSON.stringify(dataObjAlquiler))
            getDataProducts2()
            setPreloadData(true)


        }
    };



    useEffect(() => {
        //Evaluate 1st step if we have data from endpoint data rent
        setTimeout(() => {
            const objectRsv = JSON.parse(localStorage.getItem('descriptPromoRent'))
            const isDataRsv = localStorage.getItem('isCorrect')
            //Evaluate if data promo rent is null
            if (objectRsv === null && isDataRsv) {
                //console.log('EmptyOIbject')
                setPreloadData(false)
                // setStep(1)
                let getMessageApi = JSON.parse(localStorage.getItem('msgFromAPi'))
                //console.log('getMessageFromApi', getMessageApi)
                setMessage(getMessageApi)
            } else {
                setPreloadData(false)
            }

        }, 1500)
    }, [step == 2])

    //Validate all fields Fecha | Hora | Cantidad 

    useEffect(() => {
        //console.log('RSV', createRsv)

        const evalRsvObject = () => {

            if (!createRsv.fecha || !createRsv.hora || !createRsv.cantidad) {
                return true
            } else {
                return false
            }
        }

        setBtnStep(evalRsvObject())
        //console.warn('Enable Btn', '::', evalRsvObject())
        //console.log('createRsv.fecha', createRsv.fecha, "createRsv.idHoraDia", createRsv.idHoraDia, "createRsv.cantidad", createRsv.cantidad)
        // //console.log('ENABLED BUTTON', evalRsvObject())
        // //console.log('OBJE[]', createRsv.fecha, createRsv.idHoraDia, createRsv.cantidad)
    }, [createRsv])


    useEffect(() => {
        document.body.style.backgroundColor = "#f0f0f0";
    }, [])

    //Evaluate for get products rent
    const LoadingScreen = () => {
        return (
            <div className="loadingPage">
                <CircularProgress />
            </div>
        )
    }

    const handlePrevStep = () => {
        setStep(step - 1);
    };

    const handleSubmit = (event) => {

        event.preventDefault();
        saveReserveData()

        setTimeout(() => {
            //console.log('Eval:::', JSON.parse(localStorage.getItem('rsvSaved')) !== null)
            if (JSON.parse(localStorage.getItem('rsvSaved')) !== null) {
                setSubmit(true)
                //console.log('SUBMIT')
            }

        }, 1500)

    };


    const getSedeName = () => {

        const queryString = window.location.pathname;
        //console.log('queryString', queryString)
        const str = queryString
        const match = str.match(/\d+/);
        const num = match ? parseInt(match[0]) : null;
        //console.log('sedeID:', num);

        localStorage.setItem('sedeID', num)
        //save Sede
        //updateFormDateHour('sedeID', num)
        getSedeId(num).then((data) => setSedeNameBol(data.data.nombre))
        setSedeIdNum(num)
    }




    //Get list country
    const getListCountry = async () => {
        try {
            const response = await fetch(`${endPointAPiBoleraApp}api/External/GetPaisList`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "AuthorizationToken": "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e",
                    "Accept-Language": "es-MX"
                }
            });

            if (!response.ok) {
                throw new Error('Error occurred during the request.');
            }

            const responseData = await response.json();
            console.log('Response get Country', responseData);
            setCountry(responseData.data)

        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        console.log('formData.pais', e.target.value)
    };

    useEffect(() => {
        getSedeName()
        getListCountry()
        //console.log('ListCountry: ', getCountryList)
    }, [])

    useEffect(() => {
        console.log('getRENT ID:::::', localStorage.getItem('idProductoAlquiler'))
        //console.log('ListCountry: ', getCountryList)
    }, [localStorage.getItem('idProductoAlquiler')])

    //componet select hora

    const SelectHora = () => {
        return (
            <select
                id="hour-select"
                name="hora"
                style={{ width: '95%', height: 37, marginTop: 2 }}
                onChange={handleHourChange}
                value={selectedHour}
                placeholder="seleccionar hora"
            >
                <option value="" disabled selected>
                    Seleccione hora
                </option>
                {

                    getRangoHorasSede !== null ?
                        getRangoHorasSede.map((hour) => (
                            <option key={hour.idHoraDia} value={hour.descripcion} id={hour.idHoraDia}>
                                {hour.descripcion}
                            </option>
                        )) : null

                }
            </select>
        )

    }


    const handleHourChange = (event) => {
        const selectedHour = event.target.value;

        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));

        localStorage.setItem('horaReservada', selectedHour)
        ////console.log("Selected hour:", selectedHour);
        const filterIdHoraDia = getRangoHorasSede.filter(hour => hour.descripcion === event.target.value)
        ////console.log('filterIdHoraDia', filterIdHoraDia)
        const getIdHoraDia = filterIdHoraDia[0].idHoraDia
        ////console.log('formattedHour', selectedHour)
        setSelectedHour(selectedHour)

        //console.log('getIdHoraDia', getIdHoraDia)
        localStorage.setItem('idHoraDia', getIdHoraDia)

        ////console.log('Data Hour', formData)
        setCreateRsv((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    //Get Hours by Sede and Date
    const getHoursBySede = async () => {
        const rango = []
        const constDataObject = {
            fecha: localStorage.getItem('fecha'),
            idSede: getSedeIdNum

        }

        console.log('GetHoursOBJ', constDataObject, "Fecha", formDataUser)

        try {
            const response = await fetch(`https://reservasapi.bolichecostarica.com/api/External/GetHorasPorSede`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "AuthorizationToken": "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e",
                    "Accept-Language": "es-MX"
                },
                body: JSON.stringify(constDataObject)
            })

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            console.log('dataGetHoras: ', data.isCorrect)
            rango.push(data.data)
            if (!data.isCorrect) {
                alert(data.message)
            }
            //console.log('Hours', rango)
            // localStorage.setItem('rangoHoras', JSON.stringify(rango[0]))
            setRangoHoras(rango[0])
            //console.warn('GetHours', getRangoHorasSede)
            //updateFormDateHour('rangoHoras', rango)

        } catch (error) {
            //console.error(error);
        }
    }


    const QuantPeopleSelect = () => {

        const handleChange = (event) => {

            const { name, value } = event.target;
            setSelectedNumber(value);
            setCreateRsv((prevState) => ({
                ...prevState,
                'cantidad': value
            }));

            localStorage.setItem('cantidad', event.target.value)
            //console.log('selected:', selectedNumber)
            //console.log('CANTIDAD::::', value, "createRsv", createRsv, "NAME", name)

        };

        return (
            <select value={selectedNumber} onChange={handleChange} name="cantidad" className="cantidadPersonas">
                <option value="">Seleccione cantidad:</option>
                {Array.from({ length: 15 }, (_, i) => i + 1).map((value) => (
                    <option
                        key={value}
                        placeholder="Cantidad de Personas"
                        value={value}>
                        {value}
                    </option>
                ))}
            </select>
        );
    };


    const renderStepOne = () => {
        setData(0)
        localStorage.setItem('idProductoAlquiler', 0);
        console.log('status Data:', data)


        const toggleModal = () => {
            setShowModal(false);
        };

        const loadModal = () => {
            setShowModal(true);
        };

        setTimeout(loadModal, 1500)

        const alertMessage = "Para una mejor visualización y rendimiento desde el celular por favor utilizar el app de Android o iOS."


        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>


                {
                    //showModal ? <div>Using mobile Phone</div> : null
                    showModal
                        ?
                        <div className="showAlertMessage">
                            <Alert severity="info">
                                {alertMessage}
                            </Alert>
                        </div> : null


                }

                <div className="container">
                    <h2>Datos de la fecha y hora</h2>
                    <h3>Sede:{getSedeNameBol}</h3>

                    <div className="input-container">
                        <div className="contInputs">
                            <label><span>Fecha:</span></label>
                            <input
                                value={formData.fecha}
                                type="date"
                                id="fecha"
                                name="fecha"
                                onChange={handleInputChange}
                                placeholder="Fecha" />
                        </div>
                        <div className="contInputHour">
                            {/* <label><span>Hora:{' '}{selectedHour}</span></label> */}
                            <label><span>Hora:{' '}</span></label>
                            {
                                getRangoHorasSede !== null ? <SelectHora /> : null
                                //getRangoHorasSede !== null ? <TimePickerComponent dataHours={getRangoHorasSede} /> : null

                            }
                        </div>
                        <div className="contInputs">
                            <label><span>Cantidad de Personas:</span></label>
                            <QuantPeopleSelect />
                        </div>
                    </div>


                    <div>
                        <button
                            onClick={handleNextStep}
                            disabled={btnStep}
                            className={btnStep ? 'btnStepDisable' : 'btnStepEnable'}
                        >Siguiente</button>

                    </div>
                    <div id='logosApp'>
                        <div>
                            <a href="https://play.google.com/store/apps/details?id=com.consultyx.bolcr" target="_blank">
                                <img src={Google} />
                            </a>
                        </div>
                        <div>
                            <a href="https://apps.apple.com/us/app/bolcr/id6448749091" target="_blank">
                                <img src={Apple} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    //llenar reserva
    const dataCheckReserva = JSON.parse(localStorage.getItem('rsv'))
    const descriptPromoRent = JSON.parse(localStorage.getItem('descriptPromoRent'))

    const RenderRentPromos = () => {
        return <GetProductsRent />
    }


    const RenderStepTwo = () => {


        const DisplayListPromoRSV = () => {
            return (
                <div className="grid-container">
                    <RenderRentPromos />
                </div>
            )
        }

        const DisplayErrMsg = () => {
            return (
                <><h3>{message}</h3></>
            )
        }

        ////console.log('descriptPromoRent', localStorage.getItem('descriptPromoRent'))

        return (
            <div>
                <h2 style={{ fontWeight: 'bolder' }}>Datos de la Reserva</h2>


                <DisplayReserva
                    date={dataCheckReserva.fecha}
                    nameSede={localStorage.getItem('sede')}
                    hours={localStorage.getItem('horaReservada')}
                    persons={dataCheckReserva.cantidadPersonas} />

                {
                    getPackages === null
                        ? <DisplayErrMsg />
                        : <DisplayListPromoRSV />

                }


                <br />
                <div className="containerNavBtn">
                    <button onClick={handlePrevStep}>Atrás</button>
                    {console.log('ID UPDATE ::::::', data)}
                    {
                        data !== 0 ? <button onClick={handleNextStep}>Siguiente</button> : null
                    }
                </div>
            </div>
        );
    };

    const RenderStepThree = () => {

        ////console.log("Form submitted:", formData);
        const dataUserRsv = {
            'nombre': formData.nombre,
            'apellido': formData.apellido,
            'email': formData.email,
            'telefono': formData.telefono
        }

        localStorage.setItem('dataUserRsv', JSON.stringify(dataUserRsv))


        const handleEmailChange2 = (event) => {
            setEmail2(event.target.value);
        };

        const emailsMatching = formData.email === email2;
        //console.log('emailsMatch:', emailsMatching, "formData.email", formData.email, 'email2', email2)

        return (
            <div>
                <h2>Datos de la reserva</h2>

                {
                    JSON.parse(localStorage.getItem('rsvSaved')) !== null
                        ? <CheckReserve />
                        :
                        <div>
                            <DisplayReserva
                                date={dataCheckReserva.fecha}
                                nameSede={localStorage.getItem('sede')}
                                hours={localStorage.getItem('horaReservada')}
                                persons={dataCheckReserva.cantidadPersonas} />

                            <div style={{ paddingBottom: 40 }}>
                                <input
                                    type="text"
                                    id="nombre"
                                    name="nombre"
                                    value={formData.nombre}
                                    onChange={handleInputChange}
                                    placeholder="Nombre"
                                />
                                <br />

                                <input
                                    type="text"
                                    id="apellido"
                                    name="apellido"
                                    value={formData.apellido}
                                    onChange={handleInputChange}
                                    placeholder="Apellido"
                                />
                                <br />

                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    placeholder="Email"
                                />
                                <br />

                                <input
                                    type="text"
                                    id="email2"
                                    name="email2"
                                    value={email2}
                                    onChange={handleEmailChange2}
                                    placeholder="Email"
                                />
                                <br />

                                <select
                                    name="pais"
                                    label="Pais"
                                    className="countryPhone" // You can add your custom CSS class here
                                    value={formData.pais || 0}
                                    onChange={handleInputChange}
                                >
                                    <option value={0}>
                                        <em>Seleccione pais</em>
                                    </option>
                                    {getCountryList.map((country) => (
                                        <option key={country.idPais} value={country.idPais}>
                                            {country.descripcion}
                                        </option>
                                    ))}
                                </select>
                                <br />


                                <input
                                    type="tel"
                                    id="telefono"
                                    name="telefono"
                                    value={formData.telefono}
                                    onChange={handleInputChange}
                                    placeholder="Teléfono"
                                />


                            </div>
                            <br />

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <button onClick={handlePrevStep}>Atrás</button>
                                {
                                    emailsMatching && email2 !== "" ? <button type="submit" >Submit</button> : null
                                }


                            </div>
                            {emailsMatching ? (
                                <p></p>
                            ) : (
                                <Alert severity="warning" style={{ position: 'fixed', top: 0, left: 0, right: 0, justifyContent: 'center' }}>
                                    <AlertTitle>Error Emails</AlertTitle>
                                    Los campos de emails deben ser <strong>Iguales</strong>
                                </Alert>

                            )}



                        </div>

                }


            </div>
        );
    };

    const PreloaderScreen = () => {
        return (
            <div className="preloader">
                <div className="loaderAnimation" >
                    <LoadingScreen />
                    <h3 style={{ color: 'white' }} > Cargando...</h3>
                </div>
            </div >
        )
    }

    return (
        <>
            {
                console.log('DATA STORAGE: ', dateHour, 'Data User:', formDataUser)}

            <HeaderLogoBoliche />
            {
                preloadData === true ? <PreloaderScreen /> : null
            }
            {step === 1 && renderStepOne()}
            {step === 2 && RenderStepTwo()}


            <form onSubmit={handleSubmit}>
                {step === 3 && RenderStepThree()}
            </form>
        </>

    );
};

export default MultiStepForm




