import React from 'react'
import '../../sass/general.scss'
import LogoBolera from '../../images/logo-boliche-cr.png'
import '../../sass/logo.scss'
import { Grid, Link } from '@material-ui/core';
import MenuHome from '../MenuHome';
import MenuHomeMobile from '../MenuHomeMobile';
import { useMediaQuery } from '@mui/material';
import json2mq from 'json2mq';



function GetResizeResponsive() {
    const matches = useMediaQuery(
        json2mq({
            minWidth: 1024,
        }),
    );

    return (
        <>
            {matches ? <MenuHome /> : <MenuHomeMobile />}
        </>

    )
}


const HeaderLogoBoliche = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className='topBar'>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <img width="300" height="127" src={LogoBolera} sizes="(max-width: 300px) 100vw, 300px" />
                    </Grid>
                    <Grid item xs={7}>
                        <GetResizeResponsive />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default HeaderLogoBoliche