import React from 'react'
import { render } from 'react-dom'
import Card from 'react-credit-cards'
import './style.css'
import '../../sass/general.scss'

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
} from './utils'

import 'react-credit-cards/es/styles-compiled.css'
import Countdown from '../counter'
import LogoBolera from '../../images/logo-boliche-cr.png'
import '../../sass/logo.scss'
import { Link, Navigate } from 'react-router-dom'
import CryptoJS from 'crypto-js'
import { CircularProgress } from '@material-ui/core'
import SuccessReservation from '../../pages/SuccessReservation'




let json = {}


const endPointAPiBoleraApp = "https://reservaapi.azurewebsites.net/"
export default class CreditCard extends React.Component {
    state = {
        number: 0,
        name: '',
        expiry: '',
        cvc: 0,
        issuer: '',
        focused: '',
        formData: null,
        loader: false,
        redirect: false,
        month: '',
        year: '',
        paySaved: false
    }




    json = JSON.stringify(this.state);

    handleCallback = ({ issuer }, isValid) => {
        if (isValid) {
            this.setState({ issuer })
        }
        console.log('issuer', issuer, 'isValid', isValid)
    }

    handleInputFocus = ({ target }) => {
        this.setState({
            focused: target.name
        })
    }


    getExpirateDate = (expDate) => {

        const string = expDate;
        const regex = /(\d+)\/(\d+)/;
        const matches = string.match(regex);

        if (matches) {
            const beforeSlash = matches[1];
            const afterSlash = matches[2];
            console.log("Before Slash:", beforeSlash); // Output: Before Slash: 12
            console.log("After Slash:", afterSlash); // Output: After Slash: 23
            const yearComplete = afterSlash
            const month = beforeSlash
            this.setState({ year: '20' + yearComplete })
            this.setState({ month: month })
            console.log('Date Expired:', 'Month: ', month, 'year: ', yearComplete)

        }
    }

    handleInputChange = ({ target }) => {

        if (target.name === 'number') {
            //target.value = formatCreditCardNumber(target.value)
            //remove spaces on creditcard
            function removeSpaces(string) {
                return string.replace(/\s/g, "");
            }

            const originalString = target.value;
            const result = removeSpaces(originalString);
            console.log('CC Number: ', parseInt(result));
            this.setState({ number: result })

        } else if (target.name === 'expiry') {
            target.value = formatExpirationDate(target.value)
            //Get data month and year
            this.getExpirateDate(target.value)

        } else if (target.name === 'cvc') {

            target.value = formatCVC(target.value)
            console.log('CVC', parseInt(target.value))
            this.setState({ cvc: parseInt(target.value) })

        }

        this.setState({ [target.name]: target.value })

        console.log('Data Credit Card: ', this.state)


    }

    //=== SEND PAY

    sendPay = async () => {

        const rsvObject = JSON.parse(localStorage.getItem('rsvSaved'))

        const objectDataPay = {
            "IdReserva": rsvObject.idReserva,
            "IdReservaPago": 0,
            "NumeroTarjeta": parseInt(this.state.number),
            "NombrePersona": this.state.name,
            "IdMes": parseInt(this.state.month),
            "Año": parseInt(this.state.year),
            "CodigoSeguridad": parseInt(this.state.cvc)
        }

        console.log('objectWillSend', objectDataPay)


        const response = await fetch(`${endPointAPiBoleraApp}api/External/GuardarReservaPago`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "AuthorizationToken": "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e",
                "Accept-Language": "es-MX"
            },
            body: JSON.stringify(objectDataPay)
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        this.setState({ paySaved: true })
        console.log('PAY! DONE!: ->', data)
        localStorage.setItem('DataPago', JSON.stringify(data))
        const dataPago = JSON.parse(localStorage.getItem('DataPago'))
        localStorage.setItem('rsvCode', dataPago.data.reservaConfirmacion.codigoConfirmacion)
    };



    handleSubmit = e => {
        // e.preventDefault()
        // alert('You have finished payment!')
        // this.form.reset()
        console.log('Datos Guardados:', this.state)
        // Encrypt the JSON string using a secret key
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(this.state), 'my-secret-key@123').toString();
        console.log('encrypt', ciphertext)
        localStorage.setItem('usr', ciphertext)


        // this.sendPay()

        // setTimeout(() => {
        //     this.setState({ loader: false })
        //     localStorage.setItem('confirmRSV', true)
        //     window.location.href = '/success';
        // }, 3000)


    }

    redirectTest = () => {
        console.log('SUBMIT')
        this.sendPay()
        this.setState({ loader: true })
        setTimeout(() => {
            if (this.state.paySaved === true) {
                this.setState({ loader: false })
                localStorage.setItem('confirmRSV', true)
                //window.location.href = '/success';
                alert('Pago realizado')
            } else {
                alert('Pago no fue realizado')
                this.setState({ loader: false })
            }


        }, 3000)

    }






    render() {
        const { name, number, expiry, cvc, focused, issuer, paySaved } = this.state
        console.log('PaySaved', paySaved)
        return (
            <div key='Payment'>
                <div className='App-payment'>
                    <div>
                        <h1>Datos para el pago</h1>
                        <h4>Por favor complete el formulario con sus datos </h4>
                    </div>
                    {/* <Countdown /> */}

                    {
                        localStorage.getItem('DataPago') !== null ?
                            <SuccessReservation /> :
                            <>
                                <Card
                                    number={number}
                                    name={name}
                                    expiry={expiry}
                                    cvc={cvc}
                                    focused={focused}
                                    callback={this.handleCallback}
                                />
                                <div style={{ margin: 20 }}>
                                    {
                                        this.state.loader ?
                                            <CircularProgress />
                                            :
                                            <>
                                                <div>
                                                    <div className='form-group'>
                                                        <input
                                                            type='text'
                                                            name='name'
                                                            className='form-control'
                                                            placeholder='Nombre de la tarjeta'
                                                            pattern='[a-z A-Z-]+'
                                                            required
                                                            onChange={this.handleInputChange}
                                                            onFocus={this.handleInputFocus}
                                                        />
                                                    </div>
                                                    <div className='form-group'>
                                                        <input
                                                            type='tel'
                                                            name='number'
                                                            className='form-control'
                                                            placeholder='Número de la tarjeta'
                                                            pattern='[\d| ]{16,22}'
                                                            maxLength='19'
                                                            required
                                                            onChange={this.handleInputChange}
                                                            onFocus={this.handleInputFocus}
                                                        />
                                                    </div>

                                                    <div className='form-group'>
                                                        <input
                                                            type='tel'
                                                            name='expiry'
                                                            className='form-control'
                                                            placeholder='Fecha de expiración'
                                                            pattern='\d\d/\d\d'
                                                            required
                                                            onChange={this.handleInputChange}
                                                            onFocus={this.handleInputFocus}
                                                        />
                                                    </div>
                                                    <div className='form-group' >
                                                        <input
                                                            type='tel'
                                                            name='cvc'
                                                            className='form-control'
                                                            placeholder='Código de seguridad'
                                                            pattern='\d{3}'
                                                            required
                                                            onChange={this.handleInputChange}
                                                            onFocus={this.handleInputFocus}

                                                        />
                                                    </div>
                                                    <input type='hidden' name='issuer' value={issuer} />
                                                    <div className='form-actions'>
                                                        <button className="btnColor" onClick={this.redirectTest}>Enviar</button>
                                                    </div>
                                                </div>
                                            </>
                                    }


                                </div>

                            </>
                    }


                </div>
            </div>
        )
    }
}

