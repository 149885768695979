import React from 'react';
import MultiStepForm from '../components/multi-form';

function MainApp() {

    return (
        <>
            {/* <UserForm /> */}
            <MultiStepForm />

        </>

    );
}

export default MainApp;
